import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import favicon from '../../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

// images
import banner_img from "../../images/undersidor/pdf_faktura.svg";
import dokument_img from '../../images/undersidor/pdf_faktura.png';
import pdf_tolkning_img from '../../images/undersidor/pdf_tolkning.png';

const KONTAKT_BOT = "kxFV5wCG";

function Papper_pdf() {
    const [kontakt_1, set_kontakt_1] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>PDF-invoices | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Interpret and automate PDF invoices" />
                <meta name="keywords" content="PDF invoices" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Interpret and automate PDF invoices" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="https://www.crediflow.com/supplier-invoices/pdf-invoices" />
                <link rel="cannonical" href="https://www.crediflow.com/supplier-invoices/pdf-invoices" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">PDF-invoices</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Your invoices are analyzed by AI technology 
                                        that finds and extracts data at the main or 
                                        line level. Automatic matching of your suppliers 
                                        and advanced knowledge acquisition give you 
                                        increased automation and security.
                                    </p>
                                    <button onClick={() => set_kontakt_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                                    {kontakt_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={KONTAKT_BOT} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="PDF-invoices crediflow" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Home</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <Link to="/supplier-invoices/" className="flex items-center text-blue-800 hover:text-blue-600">supplier-invoices</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">pdf-invoices</span>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 pt-12" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Interpretation of PDF invoices</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                The handling of PDF invoices differs from e-invoices. 
                                PDF invoices, i.e. scanned paper invoices or 
                                PDFs attached in emails need to be interpreted.
                                <br/><br/>
                                Interpretation means that we check the supplier 
                                and read out the information you need from the invoice. 
                                We offer interpretation at header or article level 
                                depending on your needs. Interpreted data must 
                                always be checked or verified before being delivered 
                                to the next system, which means that you always 
                                have checked and correct invoice data in your system.
                                <br/><br/>
                                <span className="font-bold">PDF invoices via email &#8211; less handling than paper invoices</span>
                                <br/>
                                Handling an e-mail invoice is easier than a paper 
                                invoice as you don't have to spend time on mail 
                                handling and scanning. The invoice comes in directly 
                                to the interpreter and the data is read out. 
                                You choose whether we should check the 
                                data or whether you want to do it yourself.
                            </p>
                            <Link to="/demo/" className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Book Demo</Link>
                        </div>
                
                        <div className="w-2/3 p-4 mx-auto">
                            <img className="w-full" src={pdf_tolkning_img} alt="Interpretation of PDF invoices" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-28" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32 " >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={dokument_img} alt="Paper invoices" />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Paper invoices &#8211; requires some handling</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Keep control and work in-house or let us 
                                do it so you don't have to spend any time at all!
                                <br/><br/>
                                Most companies still receive invoices by post. 
                                Paper invoices involve more work than invoices 
                                via email as mail must be handled and the 
                                invoice scanned before interpretation and control.
                                <br/><br/>
                                With us, you choose whether you want to scan the 
                                invoices yourself or change your invoice address 
                                and let us take care of that work.
                                <br/><br/>
                                You also choose whether we should check the 
                                invoices for you or if you want to do it yourself. 
                                The flexibility of the system makes it possible 
                                to let us control parts or all of your flow 
                                during the time you wish.
                            </p>
                            <Link to="/demo" className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Book Demo</Link>
                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Papper_pdf;